.TimingInfoCard{
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  width: 80vw;
}

.timingImageContainer{
  padding: 10px;
  flex: 1;
}

.timingImageContainer img{
  width: 100%; 
}

.TimingInfoCard .description{
  padding: 10px;
  flex: 1;
}