.Header{
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100vw;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.logo{
  width: 150px;
  margin: 10px 10px 5px 10px;
  cursor: pointer;
}

/* .spacer{
  flex: 1;
} */

