.timingInfoCards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timingIntroCard{
  flex-direction: column;
}

.TimingInfoCard{
  margin-top: 5vh;
}