.About{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutInfoCard{
  width: 80vw;
  margin-top: 5vh;
  padding: 20px;
}

.quote{
  font-weight: bold;
  font-size: 18px;
}

.translation{
  font-style: italic;
}