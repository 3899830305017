.App {
  padding-top: 60px;
  background-color: #EEE;
  height: 100vh;
}


.App-link {
  color: #61dafb;
}

a:link { text-decoration: none; }

.backgroundImage{
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  opacity: .6;
  z-index: 0; 
}


.infoCard{
  color: black;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.7);
  padding: 10px;
  z-index: 50; 
}