.Contact{
}

.contactInfoCards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact h1, .Contact h2, .Contact h3{
  text-align: center;
}

.headquarters, .emailAddresses{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
  margin: 5vh 0;
  border-radius: 15px;
  padding: 10px;
  z-index: 50;
  max-width: 90vw;
}

.headquartersInfo{
  display: flex;
  flex-direction: column;
  align-items: top;
}

.headquarters h2, .emailAddresses h2{
  margin-top: 10px;
}

.locationInfo h3{
  margin-top: 0px;
}

.address{
  text-align: center;
  margin: 0 20px;
  background-color: white;
  height: 160px;
}

.headquartersMap{
  border: 0;
  margin: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.5); */
}

.emailAddressTable{
  text-align: left;
  margin: auto;
}

.emailAddressTable td{
  padding: 10px;
}

@media (min-width: 575px){
  .headquartersInfo{
    flex-direction: row;
  } 
  
  .address{
    margin: 0 20px;
  }
  
  
}