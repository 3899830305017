.Home{
  background-color: black;
}

.homeBackgroundImage{
  transition: opacity .5s ease-in-out;
  opacity: .4;
}

.transparent{
  opacity: 0;
}

.expertise{
  /* margin: 5vw; */
  text-align: center;
  position: absolute;
  top: 12vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.expertise .title{
  font-size: 32px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;

  min-width: 200px;
  max-width: 400px;
  /* margin: auto; */
}

.expertiseItems{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3vh 0px;
}

.expertiseItem{
  padding: 0;
  margin: 10px 0px;
  width: 90vw;
  text-align: center;
  cursor: pointer;
}

.expertiseItem img{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
}

.itemTitle{
  font-size: 16px;
  color: black;
  background-color: white;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
}

.expertiseItem .explanation{
  padding: 0 20px 20px 20px;
}

@media (min-width: 415px) {

  .expertise{
    top: 13vh;
  }

  .expertise .title{
    font-size: 32px;
  }  

  .expertiseItems{
    flex-direction: row;
  }  

  .expertiseItems{
    padding: 5vh 0px;
  }
  
  .expertiseItem{
    padding: 0;
    /* margin: 10px; */
    width: 45vw;
    min-width: 150px;
    max-width: 350px;
  }  

  .dynamicSpacer{
    width: 5vw;
  }  
}