.Menu{
  flex: 1;
}

.menuToggleButton{
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 2.5ex;
  padding: 3px 5px 2px 5px;
}

.horizontalPageNames{
  flex-direction: row;
  display: flex;
  padding: 20px;
  flex: 1;
  justify-content: space-around;
  min-width: 400px;
  max-width: 700px;
}

.verticalPageNames{
  flex-direction: column;
  position: absolute;
  top: 43px;
  right: 15px;
  background-color: white;
  border: 1px solid #bbb;
  padding: 0px 5px 5px 5px;
  border-radius: 5px;
}
.verticalCaretDownContainer{
  text-align: right;
  margin: -11px 0 0px 0;
  line-height: 100%;
}

.verticalCaretDown{
  margin: -5px 4px -5px 0;
  font-size: 1.1em;
  /* background-color: purple; */
  line-height: 100%;
}

.pageName{
  cursor: pointer;
}

.horizontalPageNames .pageName{
  
}

.currentPage{
  text-decoration: underline;
  font-weight: bold;
}

.verticalPageNames .pageName{
  padding: 10px 10px;
}

/* initial menu display state for small devices */
.menuToggleButton{
  position: absolute;
  right: 15px;
  top: 12px;
}

.horizontalPageNames{
  display: none;
}
.verticalPageNames{
  display: flex;
}

.menuToggleButton svg {
  color: black;
  padding: 0;
  margin: 0;
}

/* the width needs to change to be wide enough to show all page names
so if you add more pages, then make the min width wider */
@media (min-width: 600px){
  .menuToggleButton{
    display: none;
  }
  .horizontalPageNames{
    display: flex;
  }
  .verticalPageNames{
    display: none;
  }
}